.live-demo-section {
  padding: 100px 0;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.live-demo-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.live-demo-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.live-demo-title {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.title-accent {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, var(--secondary-color), var(--primary-color));
  border-radius: 2px;
}

.live-demo-subtitle {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.demo-interface {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.demo-tabs {
  display: flex;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  padding: 0;
}

.demo-tab {
  padding: 20px 30px;
  border: none;
  background: none;
  color: #666;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 3px solid transparent;
  position: relative;
}

.demo-tab:hover {
  color: var(--secondary-color);
  background: rgba(20, 190, 240, 0.05);
}

.demo-tab.active {
  color: var(--secondary-color);
  border-bottom-color: var(--secondary-color);
  background: white;
}

.demo-tab svg {
  font-size: 1.2rem;
}

.demo-view {
  padding: 30px;
  min-height: 500px;
}

/* Camera View Styles */
.camera-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.camera-feed {
  background: #f8f9fa;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #eee;
  transition: all 0.3s ease;
}

.camera-feed:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.camera-header {
  padding: 15px;
  background: var(--primary-color);
  color: white;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.camera-status {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
}

.camera-status.online {
  background: rgba(82, 196, 26, 0.2);
  color: #52c41a;
}

.camera-placeholder {
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #666;
  gap: 15px;
  background: #fff;
  position: relative;
}

.camera-placeholder svg {
  font-size: 2.5rem;
  color: var(--secondary-color);
}

.camera-info {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.camera-controls {
  display: flex;
  gap: 10px;
  padding: 15px;
  background: white;
  border-top: 1px solid #eee;
}

.control-btn {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 6px;
  background: var(--secondary-color);
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.control-btn:hover {
  background: var(--primary-color);
}

/* Dashboard View Styles */
.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.dashboard-card {
  background: #f8f9fa;
  padding: 25px;
  border-radius: 12px;
  border: 1px solid #eee;
}

.dashboard-card h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.card-icon {
  color: var(--secondary-color);
}

.metrics-grid {
  display: grid;
  gap: 20px;
}

.metric {
  display: flex;
  align-items: center;
  gap: 15px;
}

.metric-icon {
  color: var(--secondary-color);
  font-size: 1.5rem;
}

.metric-info {
  flex: 1;
}

.metric-label {
  display: block;
  margin-bottom: 8px;
  color: #666;
  font-size: 0.9rem;
}

.progress-bar {
  height: 8px;
  background: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 4px;
}

.progress {
  height: 100%;
  background: var(--secondary-color);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.metric-value {
  font-size: 0.9rem;
  color: var(--secondary-color);
  font-weight: 500;
}

.status-list {
  display: grid;
  gap: 15px;
}

.status-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: white;
  border-radius: 8px;
}

.status-icon {
  font-size: 1.2rem;
}

.status-icon.online {
  color: #52c41a;
}

.status-icon.offline {
  color: #ff4d4f;
}

.status-count {
  margin-left: auto;
  font-weight: 600;
  color: var(--secondary-color);
}

/* Analytics View Styles */
.analytics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.analytics-card {
  background: #f8f9fa;
  padding: 25px;
  border-radius: 12px;
  border: 1px solid #eee;
}

.analytics-chart {
  height: 300px;
  margin-top: 20px;
}

.chart-placeholder {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
  background: white;
  border-radius: 8px;
}

.chart-bars {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  height: 100%;
  width: 100%;
}

.chart-bar {
  flex: 1;
  background: var(--secondary-color);
  border-radius: 4px 4px 0 0;
  transition: height 0.3s ease;
}

.chart-circles {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.chart-circle {
  text-align: center;
}

.circle-value {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 8px;
}

.circle-label {
  color: #666;
}

.chart-legend {
  text-align: center;
  margin-top: 15px;
  color: #666;
  font-size: 0.9rem;
}

/* Settings View Styles */
.settings-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.settings-card {
  background: #f8f9fa;
  padding: 25px;
  border-radius: 12px;
  border: 1px solid #eee;
}

.settings-form {
  display: grid;
  gap: 20px;
}

.form-group {
  display: grid;
  gap: 8px;
}

.form-group label {
  color: #666;
  font-size: 0.9rem;
}

.settings-select {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: white;
  color: #333;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.settings-select:focus {
  border-color: var(--secondary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(20, 190, 240, 0.1);
}

.toggle-switch {
  position: relative;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.toggle-switch label:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.toggle-switch input:checked + label {
  background-color: var(--secondary-color);
}

.toggle-switch input:checked + label:before {
  transform: translateX(26px);
}

@media (max-width: 768px) {
  .demo-tabs {
    flex-wrap: wrap;
  }

  .demo-tab {
    flex: 1;
    min-width: 50%;
    padding: 15px;
    justify-content: center;
  }

  .camera-grid,
  .dashboard-grid,
  .analytics-grid,
  .settings-grid {
    grid-template-columns: 1fr;
  }
} 