.license-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 16px;
}

.license-title {
  font-size: 1.25rem;
  margin-bottom: 24px;
  color: #333;
}

.license-table-container {
  width: 100%;
  overflow-x: auto;
}

.license-table {
  width: 100%;
  border-collapse: collapse;
}

.license-table th,
.license-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.license-table th {
  width: 30%;
  background-color: #f5f5f5;
  font-weight: 600;
  color: #333;
}

.license-table td {
  color: #666;
}

.progress-container {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin: 8px 0;
}

.progress-bar {
  height: 100%;
  background-color: #4a90e2;
  transition: width 0.3s ease;
}

.camera-count {
  font-size: 0.875rem;
  color: #666;
  margin-top: 4px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .license-container {
    margin: 8px;
    padding: 16px;
  }

  .license-table th,
  .license-table td {
    padding: 8px;
  }

  .license-table th {
    width: 40%;
  }
}

.license-pricing-container {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  background: #f8fafc;
  min-height: 100vh;
}

.pricing-header-main {
  text-align: center;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-title {
  color: #1a237e;
  font-size: 2.2rem;
  font-weight: 700;
  background: linear-gradient(45deg, #1a237e, #3949ab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 0 auto;
  padding: 15px 0;
}

.pricing-subtitle {
  color: #64748b;
  font-size: 1.2rem;
  margin: 0;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  padding: 2rem;
}

.pricing-card {
  flex: 0 1 280px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  position: relative;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 520px;
}

.pricing-card::-webkit-scrollbar {
  width: 6px;
}

.pricing-card::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.pricing-card::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.pricing-card.hovered {
  transform: translateY(-15px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
}

.pricing-card.highlighted {
  border-color: #3949ab;
}

.popular-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background: #3949ab;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(57, 73, 171, 0.3);
}

.pricing-header {
  padding: 20px 15px;
  /* text-align: center; */
  border-bottom: 1px solid #e2e8f0;
}

.pricing-header h3 {
  margin: 0;
  font-size: 1.4rem;
  color: #1a237e;
  font-weight: 700;
  text-align: center;
}

.tier-subtitle {
  color: #64748b;
  margin: 6px 0;
  font-size: 0.8rem;
  /* text-align: center; */
}

.price-container {
  margin-top: 12px;
}

.price {
  font-size: 2rem;
  font-weight: 700;
  color: #1a237e;
  line-height: 1;
}

.price-period {
  color: #64748b;
  font-size: 0.8rem;
  margin-top: 4px;
}

.features-list {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.features-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
}

.feature-item {
  display: flex;
  align-items: center;
  margin: 8px 0;
  color: #475569;
  font-size: 0.8rem;
  transition: transform 0.2s ease;
}

.feature-item:hover {
  transform: translateX(5px);
}

.checkmark {
  color: #3949ab;
  margin-right: 8px;
  font-weight: bold;
  font-size: 0.9rem;
}

.select-plan-btn {
  width: calc(100% - 30px);
  margin: 10px 15px 15px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: #e8eaf6;
  color: #1a237e;
  font-size: 0.85rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-top: auto;
}

.select-plan-btn.highlighted-btn {
  background: #3949ab;
  color: white;
}

.select-plan-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.btn-arrow {
  transition: transform 0.3s ease;
}

.select-plan-btn:hover .btn-arrow {
  transform: translateX(5px);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .pricing-card {
    flex: 0 0 240px;
  }
  
  .pricing-cards {
    gap: 10px;
  }

  .feature-item {
    font-size: 0.75rem;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.pricing-card {
  animation: fadeIn 0.6s ease-out forwards;
}

.pricing-card:nth-child(1) { animation-delay: 0.1s; }
.pricing-card:nth-child(2) { animation-delay: 0.2s; }
.pricing-card:nth-child(4) { animation-delay: 0.4s; }

.base-feature .feature-text {
  color: #888; /* Lighter color to indicate inherited features */
}

.collapsible-section {
  margin-bottom: 10px;
}

.collapsible-btn {
  background-color: #e8eaf6;
  color: #1a237e;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.collapsible-btn:hover {
  background-color: #c5cae9;
}

.base-feature {
  background-color: #f0f4f8;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  position: relative;
}

.base-feature .feature-text {
  color: #555;
  font-style: normal;
}

.license-section {
  scroll-margin-top: 80px; /* Adjusts for fixed navbar */
  scroll-behavior: smooth;
}

.license-pricing-container {
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
} 