.neuralkart-home {
  min-height: 100vh;
  background: var(--background-color);
  color: var(--text-color);
} 

/* Why Choose Section */
.neuralkart-why-choose-section {
  padding: 100px 0;
  background: #f8f9fa;
  position: relative;
  width: 100%;
}

.neuralkart-section-header {
  text-align: center;
  margin-bottom: 60px;
}

.neuralkart-section-header h2 {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.neuralkart-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.neuralkart-feature-card {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.neuralkart-feature-icon {
  width: 70px;
  height: 70px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, #14bef0, #28328c);
  color: white;
}

.neuralkart-feature-title {
  font-size: 1.4rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-weight: 600;
}

.neuralkart-feature-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

/* Products Section - Enhanced */
.neuralkart-products-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
}

.neuralkart-products-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(20, 190, 240, 0.2), transparent);
}

.neuralkart-products-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.neuralkart-product-card {
  background: white;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(20, 190, 240, 0.1);
  position: relative;
  overflow: hidden;
}

.neuralkart-product-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(45deg, #14bef0, #28328c);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.neuralkart-product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(20, 190, 240, 0.1);
}

.neuralkart-product-card:hover::before {
  transform: scaleX(1);
}

.neuralkart-product-icon {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background: linear-gradient(45deg, #14bef0, #28328c);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: white;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px rgba(20, 190, 240, 0.2);
}

.neuralkart-product-card h3 {
  font-size: 2rem;
  color: #28328c;
  margin-bottom: 25px;
  font-weight: 700;
  background: linear-gradient(45deg, #14bef0, #28328c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.neuralkart-product-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.neuralkart-product-card ul li {
  padding: 12px 0;
  color: #666;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.neuralkart-product-card ul li:last-child {
  border-bottom: none;
}

.neuralkart-product-card ul li::before {
  content: '→';
  color: #14bef0;
  margin-right: 15px;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.neuralkart-product-card:hover ul li::before {
  transform: translateX(5px);
}

@media (max-width: 1024px) {
  .neuralkart-products-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .neuralkart-products-section {
    padding: 60px 0;
  }

  .neuralkart-product-card {
    padding: 30px;
  }

  .neuralkart-product-icon {
    width: 60px;
    height: 60px;
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .neuralkart-product-card h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .neuralkart-product-card ul li {
    font-size: 1rem;
    padding: 10px 0;
  }
}

/* About Section - Enhanced */
.neuralkart-about-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
}

.neuralkart-about-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(20, 190, 240, 0.2), transparent);
}

.neuralkart-about-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.neuralkart-about-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  position: relative;
}

.neuralkart-about-card {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(20, 190, 240, 0.1);
  position: relative;
  overflow: hidden;
}

.neuralkart-about-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(45deg, #14bef0, #28328c);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.neuralkart-about-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(20, 190, 240, 0.1);
}

.neuralkart-about-card:hover::before {
  transform: scaleX(1);
}

.neuralkart-about-icon {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background: linear-gradient(45deg, #14bef0, #28328c);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: white;
  margin: 0 auto 30px;
  box-shadow: 0 10px 20px rgba(20, 190, 240, 0.2);
  transition: all 0.3s ease;
}

.neuralkart-about-card:hover .neuralkart-about-icon {
  transform: rotateY(180deg);
}

.neuralkart-about-card h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  background: linear-gradient(45deg, #14bef0, #28328c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.neuralkart-about-card p {
  color: #666;
  line-height: 1.8;
  font-size: 1.1rem;
  text-align: center;
  margin: 0;
  transition: all 0.3s ease;
}

.neuralkart-about-card:hover p {
  color: #333;
}

/* Connecting lines between cards */
.neuralkart-about-cards::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, transparent, rgba(20, 190, 240, 0.1), transparent);
  z-index: 0;
}

@media (max-width: 1024px) {
  .neuralkart-about-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .neuralkart-about-cards::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .neuralkart-about-section {
    padding: 60px 0;
  }

  .neuralkart-about-cards {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .neuralkart-about-card {
    padding: 30px;
  }

  .neuralkart-about-icon {
    width: 60px;
    height: 60px;
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .neuralkart-about-card h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  .neuralkart-about-card p {
    font-size: 1rem;
  }
}

/* Enhanced Product Development Section */
.neuralkart-product-dev-section {
  padding: 100px 0;
  background: #f8f9fa;  /* Light gray background for contrast */
  position: relative;
  width: 100%;  /* Ensure full width */
  display: block; /* Ensure the section is displayed */
}

.neuralkart-product-dev-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(20, 190, 240, 0.2), transparent);
}

.section-subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-top: 1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.neuralkart-product-dev-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 20px;
  position: relative; /* Add position context */
  z-index: 1; /* Ensure grid is above background */
}

.neuralkart-product-dev-card {
  background: #ffffff;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(20, 190, 240, 0.1);
  position: relative;
  overflow: hidden;
  display: flex;  /* Enable flexbox */
  flex-direction: column;
  align-items: center;
  min-height: 400px; /* Set minimum height */
  z-index: 2; /* Ensure cards are visible */
}

.neuralkart-product-dev-card .neuralkart-product-icon {
  position: relative; /* Ensure icon is visible */
  z-index: 3;
}

/* Remove any potential conflicting styles */
.neuralkart-product-dev-card h3,
.neuralkart-product-dev-card .card-subtitle,
.neuralkart-product-dev-card ul,
.neuralkart-product-dev-card li {
  position: relative;
  z-index: 3;
  width: 100%; /* Ensure full width of content */
}

/* Ensure the gradient line at the top is visible */
.neuralkart-product-dev-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(45deg, #14bef0, #28328c);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  z-index: 4; /* Place above other elements */
}

/* Add hover state visibility */
.neuralkart-product-dev-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.neuralkart-product-dev-card:hover::before {
  transform: scaleX(1);
}

.neuralkart-product-dev-card .neuralkart-product-icon {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background: linear-gradient(45deg, #14bef0, #28328c);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: white;
  margin: 0 auto 30px;
  box-shadow: 0 10px 20px rgba(20, 190, 240, 0.2);
}

.card-subtitle {
  color: #333;
  font-size: 1.1rem;
  margin: 1rem 0;
  text-align: center;
}

.neuralkart-product-dev-card h3 {
  font-size: 2rem;
  margin: 1rem 0;
  text-align: center;
  font-weight: 700;
  background: linear-gradient(45deg, #14bef0, #28328c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.neuralkart-product-dev-card ul {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.neuralkart-product-dev-card li {
  margin: 1rem 0;
  color: #333;
  font-size: 1.1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.neuralkart-product-dev-card li:last-child {
  border-bottom: none;
}

.highlight {
  color: #14bef0;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .neuralkart-product-dev-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .neuralkart-product-dev-section {
    padding: 60px 0;
  }

  .neuralkart-product-dev-card {
    padding: 30px;
  }

  .neuralkart-product-dev-card h3 {
    font-size: 1.8rem;
  }

  .card-subtitle {
    font-size: 1rem;
  }

  .neuralkart-product-dev-card li {
    font-size: 1rem;
  }
} 