.logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Logo sizes */
.logo-container.small {
  transform: scale(0.8);
}

.logo-container.medium {
  transform: scale(1);
}

.logo-container.large {
  transform: scale(1.2);
}

.logo-symbol {
  position: relative;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cctv-camera {
  position: relative;
  width: 100%;
  height: 100%;
}

.camera-body {
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 28px;
  height: 16px;
  background: linear-gradient(135deg, #14bef0, #28328c);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(20, 190, 240, 0.3);
}

.camera-lens {
  width: 12px;
  height: 12px;
  background: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.lens-inner {
  width: 6px;
  height: 6px;
  background: linear-gradient(135deg, #28328c, #14bef0);
  border-radius: 50%;
  position: relative;
}

.lens-inner::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 2px;
  height: 2px;
  background: white;
  border-radius: 50%;
  opacity: 0.8;
}

.camera-mount {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.mount-arm {
  width: 4px;
  height: 14px;
  background: linear-gradient(to bottom, #14bef0, #28328c);
  margin: 0 auto;
}

.mount-base {
  width: 16px;
  height: 4px;
  background: linear-gradient(to right, #14bef0, #28328c);
  border-radius: 2px;
  margin-top: 2px;
}

.camera-light {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 4px;
  height: 4px;
  background: #ff4444;
  border-radius: 50%;
  animation: blink 2s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.4; }
}

.logo-text {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  letter-spacing: -0.5px;
}

.logo-text .n {
  color: #14bef0;
}

.logo-text .gage {
  background: linear-gradient(45deg, #14bef0, #28328c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Hover effects */
.logo-container:hover .camera-body {
  transform: translateX(-50%) rotate(-5deg);
  box-shadow: 0 4px 15px rgba(20, 190, 240, 0.4);
}

.logo-container:hover .lens-inner {
  transform: scale(1.1);
}

.logo-container:hover .camera-light {
  animation: blink 0.5s infinite;
}

/* Dark theme support */
@media (prefers-color-scheme: dark) {
  .logo-text {
    color: white;
  }
} 