.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  overflow: hidden;
  padding-top: 80px;
}

.hero-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, transparent 0%, rgba(248, 249, 250, 0.8) 100%);
  z-index: 2;
}

.hero-content {
  position: relative;
  z-index: 3;
  max-width: 1200px;
  padding: 0 20px;
  text-align: center;
  color: #333;
}

.hero-text-content {
  margin-bottom: 4rem;
}

.hero-title {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #333;
}

.gradient-text {
  background: linear-gradient(45deg, #14bef0, #28328c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.hero-subtitle {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 3rem;
  color: #666;
  font-weight: 400;
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.primary-btn, .secondary-btn {
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.primary-btn {
  background: linear-gradient(45deg, #14bef0, #28328c);
  border: none;
  color: white;
  box-shadow: 0 4px 15px rgba(20, 190, 240, 0.3);
}

.primary-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 25px rgba(20, 190, 240, 0.5);
}

.secondary-btn {
  background: white;
  border: 2px solid #14bef0;
  color: #14bef0;
}

.secondary-btn:hover {
  background: rgba(20, 190, 240, 0.1);
  transform: translateY(-2px);
}

.btn-icon {
  font-size: 1.2rem;
}

.hero-stats {
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1.5rem;
  background: white;
  border-radius: 12px;
  min-width: 150px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.stat-number {
  font-size: 2.8rem;
  font-weight: 700;
  background: linear-gradient(45deg, #14bef0, #28328c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.stat-label {
  font-size: 1rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 500;
}

.hero-logo {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  transform: scale(1.5);
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.8rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .hero-stats {
    flex-direction: column;
    gap: 2rem;
    margin-top: 3rem;
    padding-top: 3rem;
  }

  .stat-item {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .hero-logo {
    transform: scale(1.2);
    margin-bottom: 1.5rem;
  }

  .hero {
    padding-top: 100px;
  }

  .hero-content {
    padding: 0 20px 40px;
  }
}

@media (max-width: 480px) {
  .hero {
    padding-top: 120px;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
} 