.features-section {
  padding: 100px 0;
  background-color: #f8f9fa;
  min-height: 100vh;
  overflow: hidden;
}

.features-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.features-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.features-title {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.title-accent {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, var(--secondary-color), var(--primary-color));
  border-radius: 2px;
}

.features-subtitle {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  padding: 20px 0;
}

.feature-card {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.feature-content {
  padding: 30px;
}

.feature-icon-wrapper {
  width: 70px;
  height: 70px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
}

.icon-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  transform: scale(0.8);
  transition: transform 0.3s ease;
}

.feature-card:hover .icon-background {
  transform: scale(1.2);
}

.feature-icon-wrapper svg {
  position: relative;
  z-index: 1;
}

.feature-title {
  font-size: 1.4rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-weight: 600;
}

.feature-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.feature-highlights {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: auto;
}

.highlight-tag {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.feature-card:hover .highlight-tag {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .features-section {
    padding: 60px 0;
  }

  .features-title {
    font-size: 2.2rem;
  }

  .features-subtitle {
    font-size: 1rem;
    padding: 0 20px;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 10px;
  }

  .feature-content {
    padding: 20px;
  }
}

@media (min-width: 1400px) {
  .features-grid {
    grid-template-columns: repeat(3, 1fr);
  }
} 