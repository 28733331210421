.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 1.2rem 0;
  transition: all 0.3s ease;
  background: transparent;
}

.navbar.scrolled {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 1rem 0;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: #333;
  cursor: pointer;
}

.logo-icon {
  font-size: 2rem;
  color: #14bef0;
  filter: drop-shadow(0 2px 4px rgba(20, 190, 240, 0.3));
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.05rem;
  transition: all 0.3s ease;
  position: relative;
  padding: 0.5rem 0;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #14bef0;
  transition: width 0.3s ease;
  border-radius: 2px;
}

.nav-link:hover::after {
  width: 100%;
}

.nav-link:hover {
  color: #14bef0;
}

.login-btn {
  background: #14bef0;
  color: white;
  border: none;
  padding: 0.9rem 2rem;
  border-radius: 50px;
  font-size: 1.05rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(20, 190, 240, 0.3);
}

.login-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(20, 190, 240, 0.4);
}

.mobile-menu-icon {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: #333;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 2rem;
    flex-direction: column;
    gap: 1.5rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  .nav-links.active {
    display: flex;
  }

  .mobile-menu-icon {
    display: block;
  }

  .login-btn {
    width: 100%;
    text-align: center;
  }
}

.logo-group {
  display: flex;
  align-items: center;
}

.logo-separator {
  width: 1px;
  height: 30px;
  background: linear-gradient(to bottom, transparent, #14bef0, transparent);
  margin: 0 20px;
}

@media (max-width: 768px) {
  .logo-group {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .logo-separator {
    display: none;
  }
} 