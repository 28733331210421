.neuralkart-hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  overflow: hidden;
  padding-top: 80px;
}

.neuralkart-hero-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.neuralkart-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, transparent 0%, rgba(248, 249, 250, 0.8) 100%);
  z-index: 2;
}

.neuralkart-hero-content {
  position: relative;
  z-index: 3;
  max-width: 1200px;
  padding: 0 20px;
  text-align: center;
  color: #333;
}

.neuralkart-hero-text-content {
  margin-bottom: 4rem;
}

.neuralkart-hero-text-content h1 {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #333;
}

.neuralkart-gradient-text {
  background: linear-gradient(45deg, #14bef0, #28328c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.neuralkart-hero-subtitle {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.neuralkart-hero-description {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 3rem;
  color: #666;
  font-weight: 400;
}

.neuralkart-hero-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.neuralkart-primary-btn {
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  background: linear-gradient(45deg, #14bef0, #28328c);
  border: none;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(20, 190, 240, 0.3);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.neuralkart-primary-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 25px rgba(20, 190, 240, 0.5);
}

@media (max-width: 768px) {
  .neuralkart-hero-text-content h1 {
    font-size: 2.8rem;
  }

  .neuralkart-hero-subtitle {
    font-size: 1.8rem;
  }

  .neuralkart-hero-description {
    font-size: 1.2rem;
  }

  .neuralkart-hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .neuralkart-hero {
    padding-top: 100px;
  }

  .neuralkart-hero-content {
    padding: 0 20px 40px;
  }
} 