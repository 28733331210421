.neuralkart-logo-container {
  --network-color: #2196f3;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Logo sizes */
.neuralkart-logo-container.small {
  transform: scale(0.8);
}

.neuralkart-logo-container.medium {
  transform: scale(1);
}

.neuralkart-logo-container.large {
  transform: scale(1.2);
}

.neuralkart-logo-symbol {
  position: relative;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.neural-network {
  position: relative;
  width: 100%;
  height: 100%;
}

.nodes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.node {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--network-color);
  box-shadow: 0 0 10px rgba(20, 190, 240, 0.5);
  transition: transform 0.3s ease;
}

.node:hover {
  transform: scale(1.2);
}

/* Input Layer Nodes */
.input-node.n1 { top: 20%; left: 20%; }
.input-node.n2 { top: 50%; left: 20%; }
.input-node.n3 { top: 80%; left: 20%; }

/* Hidden Layer Nodes */
.hidden-node.n4 { top: 30%; left: 50%; }
.hidden-node.n5 { top: 70%; left: 50%; }

/* Output Layer Node */
.output-node.n6 { top: 50%; left: 80%; }

.connections {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.connections-svg {
  width: 100%;
  height: 100%;
}

.connection {
  fill: none;
  stroke: var(--network-color);
  stroke-width: 2;
  opacity: 0.3;
}

/* Pulsing animation for nodes */
@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.8; }
  100% { transform: scale(1); opacity: 1; }
}

.node {
  animation: pulse 2s infinite;
}

/* Stagger the animations */
.n1 { animation-delay: 0s; }
.n2 { animation-delay: 0.3s; }
.n3 { animation-delay: 0.6s; }
.n4 { animation-delay: 0.9s; }
.n5 { animation-delay: 1.2s; }
.n6 { animation-delay: 1.5s; }

/* Connection animation */
@keyframes flow {
  0% { stroke-dashoffset: 20; }
  100% { stroke-dashoffset: 0; }
}

.connection {
  stroke-dasharray: 4;
  animation: flow 1s linear infinite;
}

.neuralkart-logo-text {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  letter-spacing: -0.5px;
}

.neuralkart-logo-text .neural {
  color: #14bef0;
}

.neuralkart-logo-text .kart {
  background: linear-gradient(45deg, #14bef0, #28328c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Hover effects */
.neuralkart-logo-container:hover .node {
  animation-duration: 1s;
  box-shadow: 0 0 15px rgba(20, 190, 240, 0.8);
}

.neuralkart-logo-container:hover .connection {
  opacity: 0.6;
  animation-duration: 0.5s;
}

/* Dark theme support */
@media (prefers-color-scheme: dark) {
  .neuralkart-logo-text {
    color: white;
  }
}

.neural-network.animated .connection {
  stroke-dasharray: 15;
  animation: flowAnimation 2s linear infinite;
}

@keyframes flowAnimation {
  from {
    stroke-dashoffset: 30;
  }
  to {
    stroke-dashoffset: 0;
  }
}