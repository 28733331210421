.contact-section {
  padding: 100px 0;
  background-color: white;
  min-height: 100vh;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.contact-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.contact-title {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.title-accent {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, var(--secondary-color), var(--primary-color));
  border-radius: 2px;
}

.contact-subtitle {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.contact-content {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 40px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.info-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-card {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 25px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;
  border: 1px solid #eee;
}

.info-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0,0,0,0.1);
  border-color: var(--card-color);
}

.info-icon {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  transition: all 0.3s ease;
}

.info-card:hover .info-icon {
  transform: scale(1.1);
}

.info-content h3 {
  font-size: 1.1rem;
  color: var(--primary-color);
  margin-bottom: 5px;
}

.info-content p {
  color: #666;
  font-size: 0.95rem;
  margin: 0;
  line-height: 1.5;
}

.business-hours {
  background: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  border: 1px solid #eee;
}

.hours-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.hours-icon {
  color: var(--secondary-color);
  font-size: 1.4rem;
}

.hours-grid {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.hours-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
}

.hours-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.day {
  color: #666;
  font-weight: 500;
}

.time {
  color: var(--secondary-color);
  font-weight: 500;
}

.contact-form-container {
  background: white;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  border: 1px solid #eee;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  color: #666;
  font-weight: 500;
  font-size: 0.95rem;
}

.form-group input,
.form-group textarea {
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  color: #333;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--secondary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(20, 190, 240, 0.1);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #999;
}

.submit-button {
  background: var(--secondary-color);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.submit-button:hover {
  background: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(20, 190, 240, 0.3);
}

@media (max-width: 768px) {
  .contact-content {
    grid-template-columns: 1fr;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .contact-title {
    font-size: 2.2rem;
  }

  .contact-subtitle {
    font-size: 1rem;
  }

  .contact-form-container {
    padding: 25px;
  }
} 